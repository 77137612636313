<template lang="pug">
.relative-position(:style="styleSearch")
    q-input(
        ref="field"
        :model-value="query"
        standout
        type="search"
        label-color="accent"
        placeholder="Название, бренд или действующее вещество"
        @blur="blur"
        @focus="focus"
        @keydown.enter="goToQuery(query)"
        @keydown.up.prevent="focusOn(true)"
        @keydown.down.prevent="focusOn()"
        @update:model-value="input"
        :debounce="0")
        template(v-slot:prepend)
            q-icon(
                name="etabl:search"
                color="positive"
                size="sm")
        template(v-slot:append)
            base-btn(
                v-if="query"
                label="Найти"
                width="90"
                bradius="20"
                @click="goToQuery(query)"
            ).search-button
        q-menu(
            :model-value="menuState"
            fit
            no-parent-event
            no-focus
            style="height: 654px; min-height: 654px; width: 688px; min-width: 688px" 
            :offset="[0, 10]")
            q-scroll-area(
                :style="`height: 588px;`"
                ref="searchItems")
                ul.q-pa-none.search-list(v-if="queryResult.length")
                    template(v-for="(item, index) in queryResult")
                        li.q-my-md.q-pl-xl.q-pb-sm.text-weight-medium.text-uppercase.text-grey-9.font-14.not-hover(
                            v-if="typeof item === 'object' && item.type === 'title'"
                        )
                            | {{ item.title }}
                        li.q-px-xl.q-pa-sm.cursor-pointer.flex.justify-between.items-center(
                            v-else-if="typeof item === 'string'"
                            :ref="el => { if (el) searchItem[index] = el }"
                            :class="focusIndex === index ? 'search-list__focused' : ''"
                            @click.prevent="goTo(item, item)"
                        )
                            span
                                | {{ item }}
                            q-icon(
                                name="etabl:arrow_next"
                                color="primary"
                                size="18px"
                            )
                        li.row.q-pl-xl.q-pa-xs.cursor-pointer(
                            v-else
                            :ref="el => { if (el) searchItem[index] = el }"
                            :class="focusIndex === index ? 'search-list__focused' : ''"
                            @click.prevent="goTo(`/product/${item.url}`, item.name)"
                        )
                            .search-list__img.row.justify-center.items-center
                                img(:src="item.previewImage")
                            q-item-section.q-pl-md
                                q-item-label
                                    | {{ item.name }}
                                q-item-label.text-grey-12
                                    small
                                        | {{ item.subtitleShort }}
                                q-item-label.text-grey-12
                                    small
                                        | {{ item.manufacture }}
                            q-item-section.text-primary.text-weight-medium.q-ma-none.col-grow.q-pr-xl
                                .text-right.text-weight-bold.font-14(v-if="item.price")
                                    | {{ new Intl.NumberFormat('ru-RU').format(item.price) }}
                                    span.roboto ₽
                                .text-right.text-weight-bold.font-14.text-grey-12(v-else)
                                    | нет в наличии
            q-separator.full-width.q-mb-md(
                color="grey-18"
            )
            .font-16.text-positive.text-weight-bold.text-center.q-mb-lg.cursor-pointer(
                @click="goToQuery(query)"
            )
                | Показать все результаты по запросу {{ query }}
    p.description.text-primary.row(
        v-if="!scrollHide && !isActivAccessibility"
    )
        | Например:
        template(v-for="{ label, route } in example")
            router-link.link(
                :to="city ? `/${city.slug}${route}` : route"
                :title="`${label}`")
                | &nbsp; {{ label }}
    .absolute.clear-search.cursor-pointer(v-if="query")
        q-icon.q-mr-xs(
            name="etabl:cross"
            color="grey-9"
            size="xs"
            @click="clearSearch"
        )
</template>

<script>
import { useMeta } from 'quasar';
import { ref, computed, watch, onMounted, onBeforeUpdate, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseBtn from '../Base/BaseBtn.vue';

export default {
    name: 'Search',
    components: {
        BaseBtn,
    },
    props: {
        height: {
            type: Number,
            default: 300,
        },
        scrollHide: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        // объект стора
        const store = useStore();

        // объект роутера
        const route = useRoute();
        const router = useRouter();
        const limit = 20;
        
        // синхронизация поискового поля и адресной строки
        watch(() => route.query.query, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                query.value = newValue;
            }
        });

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        // поисковый параметр адресной строки
        const query = ref(route.query.query);

        // слова-примеры с ссылками
        const example = ref([
            {
                label: 'детравенол,',
                route: `/search?query=детравенол&limit=${limit}`,
            }, {
                label: 'вольтарен,',
                route: `/search?query=вольтарен&limit=${limit}`,
            }, {
                label: 'энзистал,',
                route: `/search?query=энзистал&limit=${limit}`,
            }, {
                label: 'гилан',
                route: `/search?query=гилан&limit=${limit}&noTerm=true`,
            },
        ]);

        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        // поисковые подсказки
        const suggestions = ref([]);
        watch(() => suggestions.value.length, () => arrConcat());

        // список продуктов
        const products = ref([]);
        watch(() => products.value.length, () => arrConcat());

        // реузльтат запроса
        const queryResult = ref([]);

        // состояние загрузки
        const loading = ref(false);

        // объект текстового поля
        const field = ref(null);

        // объект подсказок
        const searchItems = ref(null);

        const searchItem = ref([]);

        const newSuggest = ref(null);

        // индекс текущего элемента из поисковых подсказок
        const focusIndex = ref(-1);
        watch(() => focusIndex.value, (value) => {
            if (value >= 0) {
                typeof queryResult.value[value] === 'string'
                    ? query.value = queryResult.value[value]
                    : query.value = queryResult.value[value]?.name;
            }

            scrollTo();
        });

        const menuState = computed(() => !!(suggestions.value.length || products.value.length));

        // значение плейсхолдера для поля поиска
        const placeholder = computed(() => {
            if (process.env.CLIENT) {
                if (window.innerWidth <= '960') {
                    return 'Введите название товара';
                } else {
                    return 'Название, бренд или действующее вещество';
                }
            }

            return false;
        });

        // стили для поля поиска
        const styleSearch = computed(() => {
            return {
                'width': '100%',
                'z-index': 999,
                'top': 0,
            };
        });

        // функция поиска
        const search = async (query) => {
            loading.value = true;

            suggestions.value = [];
            products.value = [];

            if (query) {
                let result = await store.dispatch('search/NEW_SUGGESTION', { query });

                suggestions.value = result.suggest;

                if (query.length > 2) 
                    products.value = result.products;
            }

            loading.value = false;
        };

        // обработка потеря фокуса на текстовом поле
        const blur = () => {
            // suggestions.value = [];
            // products.value = [];\


            setTimeout(() => {
                field.value.blur();
            }, 200);
        };

        // очистка поисковой строки
        const clearSearch = () => {
            query.value = '';
            suggestions.value = [];
            products.value = [];

            nextTick(() => {
                field.value.focus();
            });
        };

        // обработка фокуса на подсказках
        const focusOn = (up = false) => {
            if (up) {
                if (!focusIndex.value) {
                    focusIndex.value = queryResult.value.length - 1;
                    return;
                }

                queryResult.value[focusIndex.value - 1]?.type === 'title'
                    ? focusIndex.value = focusIndex.value - 2
                    : focusIndex.value = focusIndex.value - 1;

            } else {
                if (focusIndex.value === queryResult.value.length - 1) {
                    focusIndex.value = 0;
                    return;
                }

                queryResult.value[focusIndex.value + 1]?.type === 'title'
                    ? focusIndex.value = focusIndex.value + 2
                    : focusIndex.value = focusIndex.value + 1;
            }
        };

        // обработка фокуса на поле
        const focus = async () => {
            // focusIndex.value = -1;
            // await search(query.value);
        };

        const translates = {
            'a' : 'ф',
            'b' : 'и',
            'c' : 'с',
            'd' : 'в',
            'e' : 'у',
            'f' : 'а',
            'g' : 'п',
            'h' : 'р',
            'i' : 'ш',
            'j' : 'о',
            'k' : 'л',
            'l' : 'д',
            'n' : 'т',
            'm' : 'ь',
            'o' : 'щ',
            'p' : 'з',
            'q' : 'й',
            'r' : 'к',
            's' : 'ы',
            't' : 'е',
            'u' : 'г',
            'v' : 'м',
            'w' : 'ц',
            'x' : 'ч',
            'y' : 'н',
            'z' : 'я',
            ',' : 'б',
            ';' : 'ж',
            '[' : 'х',
            ']' : 'ъ',
            '\'' : 'э',
            '.' : 'ю',
            '`' : 'ё',
            'A' : 'Ф',
            'B' : 'И',
            'C' : 'С',
            'D' : 'В',
            'E' : 'У',
            'F' : 'А',
            'G' : 'П',
            'H' : 'Р',
            'I' : 'Ш',
            'J' : 'О',
            'K' : 'Л',
            'L' : 'Д',
            'N' : 'Т',
            'M' : 'Ь',
            'O' : 'Щ',
            'P' : 'З',
            'Q' : 'Й',
            'R' : 'К',
            'S' : 'Ы',
            'T' : 'Е',
            'U' : 'Г',
            'V' : 'М',
            'W' : 'Ц',
            'X' : 'Ч',
            'Y' : 'Н',
            'Z' : 'Я',
            '<' : 'Б',
            ':' : 'Ж',
            '{' : 'Х',
            '}' : 'Ъ',
        };

        const goToQuery = async (queryInput) => {
            let arrString = queryInput.split('');

            let newArr = [];

            arrString.forEach((item) => {
                if(translates[item]) {
                    newArr.push(translates[item]);
                } else {
                    newArr.push(item);
                }
            });

            let finalString = newArr.join('');            

            router.push({
                path: city.value ? `/${city.value.slug}/search` : '/search',
                query: {
                    query: finalString,
                    limit,
                    noTerm: true,
                },
            });
        };

        //
        const goTo = async (queryInput = '', title) => {
            if (queryInput.includes('product')) {
                if(city.value) {
                    await router.push({
                        path: `/${city.value.slug}${queryInput}`,
                    });
                } else {
                    await router.push({
                        path: queryInput,
                    });
                }

                query.value = title;
                return void blur();
            }

            if (queryInput.length) {
                if (focusIndex.value >= 0) {
                    if (focusIndex.value > suggestions.value.length) {
                        if(city.value) {
                            await router.push({
                                path: `/${city.value.slug}/product/${queryResult.value[focusIndex.value].url}`,
                            });
                        } else {
                            await router.push({
                                path: `/product/${queryResult.value[focusIndex.value].url}`,
                            });
                        }
                        query.value = queryResult.value[focusIndex.value].name;
                    } else {
                        router.push({
                            path: city.value ? `/${city.value.slug}/search` : '/search',
                            query: {
                                query: queryResult.value[focusIndex.value],
                                limit,
                            },
                        });
                    }
                } else {
                    router.push({
                        path: city.value ? `/${city.value.slug}/search` : '/search',
                        query: {
                            query: queryInput,
                            limit,
                        },
                    });
                }
            } else if (!queryResult.value[focusIndex.value]) {
                router.push({
                    path: city.value ? `/${city.value.slug}/search` : '/search',
                    query: {
                        query: query.value,
                    },
                });
            } else if (typeof queryResult.value[focusIndex.value] === 'string') {
                if (route.query.query !== queryResult.value[focusIndex.value]) {
                    
                    query.value = queryResult.value[focusIndex.value];
                    router.push({
                        path: city.value ? `/${city.value.slug}/search` : '/search',
                        query: {
                            query: queryResult.value[focusIndex.value],
                        },
                    });
                }
            } else {
                query.value = queryResult.value[focusIndex.value]?.name;
                if(city.value) {
                    await router.push({
                        path: `/${city.value.slug}/product/${queryResult.value[focusIndex.value]?.url}`,
                    });
                } else {
                    await router.push({
                        path: `/product/${queryResult.value[focusIndex.value]?.url}`,
                    });
                }
            }

            blur();
        };

        const input = async (value) => {
            query.value = value;
            focusIndex.value = -1;
            await search(value);
        };

        const arrConcat = () => {
            queryResult.value = [];
            const title = products.value.length
                ? [{
                    type: 'title',
                    title: 'Товары',
                }]
                : [];

            queryResult.value = Array.prototype.concat(suggestions.value.concat(title.concat(...products.value)));
        };

        const scrollTo = () => {
            let scrollArea = searchItems.value;
            let duration = 0;
            let scrollTarget;

            if (focusIndex.value >= 0) {
                let el = searchItem.value[focusIndex.value];
                scrollTarget = el?.offsetTop + el?.offsetHeight / 2 - props.height / 2;
            } else {
                scrollTarget = 0;
            }

            scrollArea?.setScrollPosition('vertical', scrollTarget, duration);
        };

        useMeta(() => {
            return {
                script: {
                    ldJson: {
                        type: 'application/ld+json',
                        innerHTML: `{
                            "@context": "https://schema.org/",
                            "@type": "WebSite",
                            "url": "https://${process.env.APP_SERVICE_HOSTNAME}",
                            "potentialAction": {
                                "@type": "SearchAction",
                                "target": "https://${process.env.APP_SERVICE_HOSTNAME}/search?query={query}",
                                "query": "required",
                                "query-input": "https://${process.env.APP_SERVICE_HOSTNAME}/search?query={query}"
                            }
                        }`,
                    },
                },
            };
        });

        onMounted(() => {
            if (route.name === 'search') {
                query.value = route.query.query;
            }
        });

        onBeforeUpdate(() => {
            searchItem.value = [];
        });

        return {
            query,
            example,
            suggestions,
            products,
            queryResult,
            loading,
            focusIndex,
            menuState,
            placeholder,
            styleSearch,
            search,
            blur,
            clearSearch,
            focusOn,
            focus,
            goTo,
            input,
            field,
            searchItems,
            searchItem,
            newSuggest,
            goToQuery,
            city,
            isActivAccessibility,
            translates,
        };
    },
};
</script>

<style lang="scss" scoped>
.q-field {
    width: 100%;
}

.description {
    position: absolute;
    bottom: -39px;
    left: 53px;
    font-size:12px;

    @media (max-width: 1280px) {
        font-size: 14px;
        left: 50px;
        bottom: -38px;
    }

    @media (max-width: 960px) {
        bottom: -36px;
        a:nth-child(n+2):nth-child(-n+3) {
            display: none;
        }
    }
}

.q-field :deep(.q-field__control) {
    border: 2px solid $positive;
    border-radius: 20px;
    background: #fbfafa !important;
    padding-left: 1em;
    padding-right: 0;
    height: 44px;
    overflow: hidden;

    // @media (max-width: 1280px) {
    //     padding-left: 10px;
    //     padding-right: 10px;
    // }

    &::before {
        background: transparent;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        height: 38px;
        border-radius: 10px;
        border: none;
        background: $grey-17 !important;
    }
}

.q-field :deep(.q-field__append) {
    cursor: pointer;

    &:nth-of-type(2) {
        background: $app-button-gradient;
        border-radius: $button-border-radius;
        width: 135px;
        height: 100%;
        padding-left: 0.7em;
        position: relative;
        left: 2px;
    }

    &:nth-of-type(3) {
        position: absolute;
        right: 24px;
        height: 100%;
        color: $positive;
        background: $secondary !important;

        @media screen and (max-width: $breakpoint-mobile) {
            background: $grey-17 !important;
        }
    }
}

.q-field :deep(.q-field__native) {
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 4px 0;

    @media(max-width: 1280px) {
        padding: 2px 0 2px 11px;
    }

    @media(max-width: 960px) {
        font-size: 14px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        background-color: $grey-17 !important;
    }

    &::placeholder {
        color: $grey-12;
    }
}

.q-field.q-field--focused :deep(.q-field__control) {
    box-shadow: none;
    background: rgba($secondary, 1) !important;

    &::before {
        background: transparent;
    }

    input.q-field__native {
        color: $primary !important;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        background: $grey-17 !important;
    }
}

.q-field :deep(.q-field__prepend) {
    height: 100%;

    @media(max-width: 1280px) {
        padding: 0
    }
}

 .q-field :deep(.q-field__append:nth-of-type(3)) {
    right: 0px;
    z-index: 999;
 }

.search-list {
    list-style: none;
    box-sizing: border-box;

    & li {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;

        &.not-hover {
            border-bottom: 1px solid $grey-16;
        }

        &:hover:not(.not-hover) {
            background-color: $grey-3;
            border-top: 1px solid $grey-17;
            border-bottom: 1px solid $grey-17;
        }
    }

    &__focused {
        background-color: $grey-3;
        border-top: 1px solid $grey-17 !important;
        border-bottom: 1px solid $grey-17 !important;
    }

    &__img {
        width: 75px;
        height: 75px;

        & > img {
            max-width: 75px;
            max-height: 75px;
        }
    }
}

.clear-search {
    right: 110px;
    top: 50%;
    transform: translateY(-52%);
}

.q-scroll-area {
    @media screen and (max-width: $breakpoint-mobile) {
        z-index: 1999;
    }
}
</style>
