<template lang="pug">
.spinner-block(
    :style="[`background: rgba(255, 255, 255, ${opacity});`, fullVh ? 'height: 100vh !important' : '']"
    :class="fixed ? 'fixed-top-left' : 'absolute-top-left'")
    .absolute-center.column.items-center
        slot(
            v-if="slots.spinner"
            name="spinner")
        q-spinner(
            v-else
            :thickness="thickness"
            :size="size"
            :color="color"
            size="40px")
        slot(name="text")
</template>

<script>
export default {
    name: 'BaseFullSpinner',
    props: {
        thickness: {
            type: Number,
            default: () => 3,
        },
        size: {
            type: String,
            default: () => '3em',
        },
        color: {
            type: String,
            default: () => 'positive',
        },
        fixed: {
            type: Boolean,
            default: () => false,
        },
        opacity: {
            type: [Number, String],
            default: 0.8,
        },
        fullVh: {
            type: Boolean,
            default: () => false,
        },
    },
    setup(_, { slots }) {

        return { slots };
    },
};
</script>

<style lang="scss" scoped>
.spinner-block {
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
}
</style>
