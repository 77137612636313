<template lang="pug">
.row.justify-start.items-center.q-pa-sm(style="gap: 0 24px;")
    q-icon.cursor-pointer(name='etabl:glassesAccessibility' size='30px' :color="isActivPanel ? 'black' : 'white'" @click='foo()' title="Версия для слабовидящих")
    template(v-if="isVisiblePanel")
        .row(style="gap: 0 24px;")
            .row.column
                .font-16.text-black Размер шрифта
                .row.justify-center(style="gap: 0 12px;")
                    q-btn(outline round color="black" icon="add" @click='add()')
                    q-btn(outline round color="black" icon="remove" @click='remove()')
            .row.column
                .font-16.text-black Изображения
                .row.justify-center(style="gap: 0 12px;")
                    q-btn(outline color="black" label="Показать" @click='addImg()')
                    q-btn(outline color="black" label="Скрыть" @click='removeImg()')
            .row.column
                .font-16.text-black Кернинг
                .row.justify-center(style="gap: 0 12px;")
                    q-btn(outline color="black" icon="add" @click='addLetterSpacing()')
                    q-btn(outline color="black" icon="remove" @click='removeLetterSpacing()')
            q-btn(outline color="black" label="Сбросить настройки" @click='setDefault()')
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { LocalStorage } from 'quasar';
export default {
    name: 'mainAccessibility',
    props: {
        isVisiblePanel: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        const store = useStore();

        const tmp = ref('off');
        const block = 'none';
        const isActiv = computed(() => store.getters['styles/isActiv']);
        const isActivPanel = computed(() => store.getters['styles/isActivPanel']);

        const foo = () => {
            store.commit('styles/SET_ACTIV_PANEL');
        };

        const add = () => {
            store.commit('styles/ADD_COUNT');
        };

        const remove = () => {
            store.commit('styles/REMOVE_COUNT');
        };

        const addImg = () => {
            store.commit('styles/SET_IMAGES', 'block');
            store.commit('styles/SET_ACTIV', false);
        };

        const removeImg = () => {
            store.commit('styles/SET_IMAGES', 'none');
            store.commit('styles/SET_ACTIV', true);
        };

        const addLetterSpacing = () => {
            store.commit('styles/ADD_LETTER_SPACING');
        };

        const removeLetterSpacing = () => {
            store.commit('styles/REMOVE_LETTER_SPACING');
        };

        const setDefault = () => {
            store.commit('styles/SET_DEFAULT_DATA');
        };

        onMounted(() => {
            const val = LocalStorage.getItem('isActivAccessibilityUser');
            if (val !== null) {
                store.commit('styles/SET_ALL_SETTINGS', val);
            }
        });

        return {
            foo,
            add,
            remove,
            addImg,
            removeImg,
            addLetterSpacing,
            removeLetterSpacing,
            setDefault,
            block,
            isActiv,
            isActivPanel,
            tmp,
        };
    },
};
</script >
