<template lang="pug">
q-spinner(
    :thickness="thickness"
    :size="size"
    :color="color")
</template>

<script>
export default {
    name: 'Spiner',
    props: {
        thickness: {
            type: Number,
            default: () => 3,
        },
        size: {
            type: String,
            default: () => '3em',
        },
        color: {
            type: String,
            default: () => 'positive',
        },
    },
};
</script>
