<template lang="pug">
.absolute.column.drop-down-menu.q-py-sm.relative
    router-link.q-px-md.q-py-sm.font-13(:to="city ? `/${city.slug}/personal-area?tab=profile` : '/personal-area?tab=profile'" title="Личный кабинет") Личный кабинет
    .separator.full-width
    .q-px-md.q-py-sm.font-13.text-negative.text-weight-medium.cursor-pointer(
        @click.prevent="signOut") Выйти
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    name: 'DropDownMenu',
    setup(props, { emit }) {
        // объект стора
        const store = useStore();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const signOut = () => store.commit('user/SIGNOUT');

        return {
            signOut,
            city,
        };
    },
};
</script>

<style lang="scss" scoped>
.drop-down-menu {
  min-width: 180px;
  border-radius: 10px;
  border-top: 1px solid $grey-6;
  background-color: $secondary;
  z-index: 9999;
  box-shadow: 0 6px 7px 2px rgb(0 24 51 / 10%);
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: "";
    width: 100%;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
  }

  &:after {
    content: "";
    width: 25px;
    height: 25px;
    border: 1px solid $secondary;
    border-left-color: $grey-6;
    border-top-color: $grey-6;
    background-color: $secondary;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
    z-index: 0;
  }

  & a,
  & div {
    text-decoration: none;
    color: $primary;
    z-index: 1;

    &:hover {
      background-color: $green-13;
    }
  }

  .separator {
    height: 1px;
    background-color: $grey-3;
  }
}
</style>
